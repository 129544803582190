import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"
import VueCookies from 'vue3-cookies';

import "./assets/css/bootstrap.min.css";
import "./assets/css/style1.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "./assets/fontawesome/css/fonts.css";

import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import "vue-awesome-paginate/dist/style.css";

import globalProperties from './globalServices';
import VueAwesomePaginate from "vue-awesome-paginate";
import Clipboard from "v-clipboard";


// Prevent reloading when navigating with the back button while a modal or offcanvas is open
window.addEventListener('popstate', () => {
    if (document.querySelector('.modal.show')) {
        window.location.reload();
    }
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    if (document.querySelector('.modal.show')) {
        next(false);
        return;
    }
    let routeName=['AboutUs','terms-conditions','responsible-gaming','faq','sport-event-detail','login','sign-up','forget-password'];
    const isLoggedIn = store.getters.isAuthenticated;
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const siteSettings = store.getters.siteSettings;
    

    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
        } else {
            next();
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        } else {
            if(routeName.includes(to.name)){
                next();
            } else{
                if (to.path === '/') {
                    if (isLoggedIn) {
                        next({ name: 'Home' });
                    } else {
                        next();
                    }
                } else if (to.path !== '/') {
                    next();
                }
            }
        }
    }
});


const app = createApp(App);
app.use(globalProperties);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.use(Clipboard);
app.mount('#app');

