const state = {
    siteSettings: null,
    gamesData: null,
    domainName: (window.location.hostname.split('.').slice(-2)[0] ? window.location.hostname.split('.').slice(-2)[0].toUpperCase() : ''),
    clickBetValue: null,
    favouriteGames: [],
    globalLoader:false,
    depositAmount: 0,
    languagesData:null,
    notification:0,
    siteVersion:null,
    successMsg: null,
    errorMsg: null,
    underMaintenance:null,
    sports_list:null,
    walletDetails:null,
    loggedUser:null,
    loggedInUserIds: []
};
const getters = {
    siteSettings: state => state?.siteSettings,
    languagesData: state => state?.languagesData,
    gamesData: state => state?.gamesData,
    domainName: state => state?.domainName,
    clickBetValue: state => state?.clickBetValue,
    clickBetActive: state => state?.clickBetValue?.find(item => item.isSelected) ?? false,
    favouriteGames:state => state?.favouriteGames,
    globalLoader:state => state?.globalLoader,
    depositAmount:state => state?.depositAmount,
    getNotification: state => state?.notification,
    siteVersion: state => state?.siteVersion,
    successMsg: state => state?.successMsg,
    errorMsg: state => state?.errorMsg,
    underMaintenance: state => state?.underMaintenance,
    sports_list: state => state?.sports_list,
    walletDetails: state => state?.walletDetails,
    loggedUser: state => state?.loggedUser,
    isUserLoggedIn: (state) => (userId) => state.loggedInUserIds.includes(userId)
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setLanguagesData({ commit }, languagesData) {
        commit('setLanguagesData', languagesData);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setClickBetValue({ commit }, clickBetValue) {
        commit('setClickBetValue', clickBetValue);
    },
    setFavouriteGames({ commit }, favouriteGames) {
        commit('setFavouriteGames', favouriteGames);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setNotification({ commit }, notification) {
        commit('setNotification', notification);
    },
    setSiteVersion({ commit }, siteVersion) {
        commit('setSiteVersion', siteVersion);
    },
    showSuccessModal({ commit }, message) {
        commit('showSuccessModal', message);
        setTimeout(() => {
            commit('showSuccessModal', null);
        }, 2000);
    },
    showErrorModal({ commit }, message) {
        commit('showErrorModal', message);
        setTimeout(() => {
            commit('showErrorModal', null);
        }, 2000);
    },
    setUnderMaintenance({ commit }, underMaintenance) {
        commit('setUnderMaintenance', underMaintenance);
    },
    setSportsList({ commit }, sports_list) {
        commit('setSportsList', sports_list);
    },
    setWalletDetails({ commit }, walletDetails) {
        commit('setWalletDetails', walletDetails);
    },
    setLoggedUser({ commit }, loggedUser) {
        commit('setLoggedUser', loggedUser);
    },
    setloggedInUserIds({ commit }, userId) {
        commit('setloggedInUserIds', userId);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings;
    },
    setLanguagesData(state, languagesData) {
        state.languagesData = languagesData;
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData;;
    },
    setClickBetValue(state, clickBetValue) {
        state.clickBetValue = clickBetValue;
    },
    setFavouriteGames(state, favouriteGames) {
        state.favouriteGames = favouriteGames;
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setDepositAmount(state, depositAmount){
        state.depositAmount =  depositAmount
    },
    setNotification(state) {
        state.notification = state.notification+1;
    },
    setSiteVersion(state, siteVersion) {
        state.siteVersion = siteVersion;
    },
    showSuccessModal(state, message) {
        state.successMsg = message
    },
    showErrorModal(state, message) {
        state.errorMsg = message
    },
    setUnderMaintenance(state, underMaintenance) {
        state.underMaintenance = underMaintenance;
    },
    setSportsList(state, sports_list) {
        state.sports_list = sports_list;
    },
    setWalletDetails(state, walletDetails) {
        state.walletDetails = walletDetails;
    },
    setLoggedUser(state, loggedUser) {
        state.loggedUser = loggedUser;
    },
    setloggedInUserIds(state, userId){
        if (!state.loggedInUserIds.includes(userId)) {
            state.loggedInUserIds.push(userId);
        }
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};