const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const Games = () => import(/* webpackChunkName: "games" */ './views/Games.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'games/:type',
            name: 'Games',
            component: Games
        }
    ],
}

export default GamesRoutes;