<template>
	<div>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe"> </div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications" />
	<Loader v-if="!$siteSettings?.data" />
	<div v-if="onLine && $siteSettings?.data">
		<transition name="fade">
			<div class="preloader d-flex align-items-center justify-content-center" v-if="globalLoader">
				<div class="cssload-container">
					<div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
				</div>
				<div class="bodymovinanim" ref="svgContainer" v-if="globalLoader"></div>
			</div>
		</transition>
		<!-- <router-view v-if="this.$route.name == 'Home' || this.$route.name == 'AboutUs' || this.$route.name == 'terms-conditions' || this.$route.name == 'responsible-gaming' || this.$route.name == 'faq'" /> -->
		<div>
			<DesktopHeader v-if="!underMaintenance" />
			<MobileHeader v-if="!underMaintenance"/>
			<Login ref="login_modal" />
			<SignUp />
			<section class="sports-gamming-sec">
				<div class="sports-exch-container">
					<div class="row gx-0 gx-lg-2">
						<!-- <div class="bodymovinanim"></div> -->
						<SideMenu v-if="!underMaintenance"/>
						<div class="col-md-12 col-lg-12 col-xl-10">
							<div :class="($route.name == 'horse-bet-details' || $route.name == 'sport-event-detail') ? 'bet-main-container' : 'body-main-container'">
								<div class="mobile-switch-theme fixedTop" v-if="$route.name == 'Home' || $route.name == 'Home1'" :class="{ 'demoBtn' : this.$auth.status == false }">
									<div class="news-bar" v-if="$siteSettings?.data?.announcement">
										<marquee behavior="scroll" direction="" scrollamount="6" :style="{ color: $siteSettings?.data?.announcement?.color_code }" 
										>{{ $siteSettings?.data?.announcement.msg }}</marquee>
										<div class="news-title"> 
											<img class="speaker-dark" src="@/assets/images/speaker.svg" alt="news" loading="lazy"> 
											<img class="speaker-light" src="@/assets/images/speaker-light.svg" alt="news" loading="lazy">
										</div>
									</div>
									<div class="login-btn" v-if="!$auth.status">
										<button type="button" @click="demoLogin()">{{$t('demo', 'Demo')}}</button>
									</div>
								</div>
								<template v-if="$route.name != 'Home'">
									<NewsWalletHeader></NewsWalletHeader>
								</template>
								<div class="center-main-content">
									<div class="row g-2">
										<router-view></router-view>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div class="go-top-sec" v-if="!statementsRoutes.includes($route.name)">
				<div class="container">
					<!-- <div class="fixed-bottom-btns-sec">							
						<div class="top-scroll">
							<button class="goTop-btn" v-if="showButton" @click="scrollToTop"><i class="fa-solid fa-angle-up"></i></button>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<div v-if="successMsg" :class="{ show: successMsg }" style="z-index: 9999999999999999;" id="successfullyModal" class="successfully-wrapper successfullytoaster-sec">
		<SuccessModal :message="successMsg" /> </div>
	<div v-if="errorMsg" :class="{ show: errorMsg }" style="z-index: 9999999999999999;" id="wrongtoaster" class="successfully-wrapper successfullytoaster-sec">
		<ErrorModal :message="errorMsg" /> </div>
	</div>
	<div ref="click_logout_msg" @click="$errorToast('You are logout from this device.')"
		style="display: hidden;">
	</div>
	<Offers></Offers>
	<CopyUserIdModal v-if="$auth.status" />
</template>

<script>
import lottie from 'lottie-web';
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import { mapGetters } from 'vuex';
import socketService from "./socket/sport-socket-service";
import { defineAsyncComponent } from 'vue';
import ErrorModal from './shared/components/modal/ErrorModal.vue';
import SuccessModal from './shared/components/modal/SuccessModal.vue';
import NotificationAlert from './shared/components/modal/NotificationAlert.vue';
export default {
	name: "App",
	components: {
		SideMenu: defineAsyncComponent(() =>
			import('./shared/components/header/SideMenu.vue')
		),
		DesktopHeader: defineAsyncComponent(() =>
			import('./shared/components/header/DesktopHeader.vue')
		),
		MobileHeader: defineAsyncComponent(() =>
			import('./shared/components/header/MobileHeader.vue')
		),
		NewsWalletHeader: defineAsyncComponent(() =>
			import('./shared/components/header/NewsWalletHeader.vue')
		),
		Banner: defineAsyncComponent(() =>
			import('@/shared/components/banner/Banner.vue')
		),
		NewEvent: defineAsyncComponent(() =>
			import('./shared/components/new-event/NewEvent.vue')
		),
		SportSelection: defineAsyncComponent(() =>
			import('./shared/components/sport-selection/SportSelection.vue')
		),
		Support: defineAsyncComponent(() =>
			import('./shared/components/support/Support.vue')
		),
		ErrorModal,SuccessModal,
		Login: defineAsyncComponent(() =>
			import('@/modules/authorization/components/Login.vue')
		),
		SignUp: defineAsyncComponent(() =>
			import('@/modules/authorization/components/SignUp.vue')
		),
		Offers: defineAsyncComponent(() => 
			import('./shared/components/modal/Offers.vue')
		),
		CopyUserIdModal: defineAsyncComponent(() =>
			import('./shared/components/modal/CopyUserIdModal.vue')
		),
		Loader: defineAsyncComponent(() =>
			import('./shared/components/loader/Loader.vue')
		),
		NotificationAlert
	},
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			loading: false,
			showButton: false,
			statementsRoutes: ['account-statment', 'sportsbook-result', 'live-casino-result','activity-log', 'bets', 'profit-loss', 'profit-loss-report-detail', 'casino-profit-loss-report-details'],
		}
	},
	computed: {
		...mapGetters(['successMsg', 'errorMsg', 'siteVersion', 'underMaintenance', 'globalLoader', 'isUserLoggedIn']),
		isDemo(){
            return localStorage.getItem('isDemo')
        },
	},
	async created() {
		this.socketAllEvents()
	},
	mounted() {
		this.getSiteVersion();
		this.addLoaderAnimation()
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.emitLogoutMsg = this.$refs.click_logout_msg
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		this.$errorToast(null);
		this.$successToast(null);
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener("scroll", this.handleScroll);
		if (this.$auth.status) {
			if(this.$store.getters?.stateUser?.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters?.stateUser?.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
		window.removeEventListener("scroll", this.handleScroll);
	},

	methods: {
		demoLogin(){
			this.$refs.login_modal.demoUserLogin();
		},
		addLoaderAnimation() {
			const svgContainer = document.querySelector('.bodymovinanim');
			if (svgContainer) {
				lottie.loadAnimation({
					container: svgContainer,
					path: 'https://wver.sprintstaticdata.com/v33/static/front/js/Loader-v6.0.json',
					loop: true,
					autoplay: true,
				});
			}
		},
		getSiteVersion() {
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response.data.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings?.data) {
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.getSiteSettingsServiceCall();
						} else {
							this.setSiteSttings(this.$siteSettings?.data);
						}

						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {

						let resData = response?.data.data;
						let casino;
						if(response?.data.data.casino_url) {
							const getCasinoGame = async () => {
							let gameResp = await axios.get(response?.data.data.casino_url)
							return gameResp.data
							};
							casino = await getCasinoGame();
						} else {
							casino = response?.data.data?.casino
						}	
						this.$store.dispatch('setSettings', resData.site_settings);
						this.$store.dispatch('setGamesData', casino);
						this.setSiteSttings(resData.site_settings);

					}
					this.$store.dispatch('setGlobalLoader', false);
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.$errorToast(error[0]);
				}
			});
		},
		setSiteSttings(siteData) {
			if (siteData.language_translate_list) {
				this.$setCurrentLangData(siteData.language_translate_list, siteData.language_code, siteData.aws_url)
				this.$store.dispatch('setLanguagesData', siteData.language_translate_list);
			}

			this.$store.dispatch('setSettings', siteData);
			this.setManifest(siteData);

			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);

			let is_offer = localStorage.getItem("is_offer");
			if(siteData.meta_tags) {
				document.head.innerHTML += siteData.meta_tags;
			};
			if(siteData.g_tags) {
				this.setGoogleAnalyticsTag(siteData.g_tags);
			};
			if(siteData.site_map) {
				const sitemapLink = document.createElement("link");
				sitemapLink.rel = "sitemap";
				sitemapLink.type = "application/xml";
				sitemapLink.title = "Sitemap";
				sitemapLink.href = siteData.aws_url + siteData.storage_path.site_map + siteData.site_map;
				document.head.appendChild(sitemapLink);
			};
			try {
				if (siteData.front_css != null && siteData.front_css != '') {
					const styleElement = document.createElement('style');
					styleElement.type = 'text/css';
					styleElement.appendChild(document.createTextNode(siteData.front_css));
					document.head.insertAdjacentElement('beforeend', styleElement);
				}
			}
			catch (error) {
				console.log("ERROR 1: ", error);
			}
			let userId = this.$auth?.user?.userid
			if (this.$auth?.status && localStorage.getItem('login_type') == 1 && !this.isUserLoggedIn(userId) && !this.isDemo && this.$auth.user.last_login_at) {
				this.$store.dispatch('setloggedInUserIds', userId);
				setTimeout(() => {
					this.$helper.openModal('#useridmodal')
				}, 500);
			} else{
				if (this.$auth?.status && siteData.offer != null && is_offer == null && this.$siteSettings?.data?.business_type == 2 && this.$auth.user.last_login_at) {
					this.$helper.openModal('#offer_pop_up')
				}
			} 

			const title = document.getElementById("title");
			title.text = siteData.domain_name;
			if (this.$auth.status) {
				this.getWalletBalance();
				this.getFavourites();
			}
		},
		setManifest(resData) {
			let manifestName = resData?.domain_name?.charAt(0)?.toUpperCase() + resData?.domain_name?.slice(1)?.toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);

			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);
		},
		setGoogleAnalyticsTag(gTag) {
			let script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;

			let script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${gTag}');
			`;

			document.head.appendChild(script1);
			document.head.appendChild(script2);
		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					user.earnBonus = response.data.eb;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken();
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					console.log(error);
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				if (refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
			});
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkforAccountModule() {
			const restrictedRoutes = ['sportsbook-result', 'account-statment', 'live-casino-result', 'bets',];
			return !restrictedRoutes.includes(this.$route.name);
		},
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data, 'notification').then(response => {
					this.loadingSignUp = false;
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.$errorToast(error[0]);
				});
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							// this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		handleScroll() {
			if (window.scrollY >= 50) {
				this.showButton = true;
			} else {
				this.showButton = false;
			}
        },
        scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
        }
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		},
	}
};

</script>
