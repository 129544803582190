const RulesModule = () => import(/* webpackChunkName: "static-module" */ './views/Module.vue');
const Rules = () => import(/* webpackChunkName: "rules" */ './views/Rules.vue');

const RulesRoutes = {
    path: '/',
    component: RulesModule,
    children: [
        {
            path: 'rules',
            name: 'rules',
            component: Rules,
        }
    ],
}

export default RulesRoutes;