
const state = {
    cricFightMatchDetails: null,
    tutorialSetup : null,
    matchPlayerList : null,
    addedTeamData : null,
    myTeamData : null,
    // updatedTeamName : null,
    contestType: null,
    myContest: null
};
const getters = {
    cricFightMatchDetails: state => state?.cricFightMatchDetails,
    tutorialSetup: state => state?.tutorialSetup,
    matchPlayerList: state => state?.matchPlayerList,
    addedTeamData: state => state?.addedTeamData,
    myTeamData: state => state?.myTeamData,
    getTeamName: state => state?.myTeamData?.team_name || '',
    contestType: state => state?.contestType,
    myContest: state => state?.myContest,
};
const actions = {
    setCricFightMatchDetails({ commit }, cricFightMatchDetails){
        commit('setCricFightMatchDetails', cricFightMatchDetails)
    },
    setTutorials({commit},tutorials){
        commit('setTutorials', tutorials);
    },
    setMatchPlayerList({commit},matchPlayerList){
        commit('setMatchPlayerList', matchPlayerList);
    },   
    setAddedTeamPlayers({commit},team){
        commit('setAddedTeamPlayers', team);
    },   
    setMyTeamData({commit},data){
        commit('setMyTeamData', data);
    },  
    updatedTeamName({commit},newTeamName){
        commit('setUpdatedTeamName', newTeamName);
    },
    // setUpdatedTeamName({commit},data){
    //     commit('setUpdatedTeamName', data);
    // },   
    setContestType({commit},data){
        commit('setContestType', data);
    },   
    setMyContest({commit},data){
        commit('setMyContest', data);
    },   
}
const mutations = {
    setCricFightMatchDetails(state, cricFightMatchDetails){
        state.cricFightMatchDetails = cricFightMatchDetails 
    },
    setTutorials(state, tutorials){
        state.tutorialSetup = tutorials
    },
    setMatchPlayerList(state, matchPlayerList){
        state.matchPlayerList = matchPlayerList
    },
    setAddedTeamPlayers(state, team){
        state.addedTeamData = team
    },
    setMyTeamData(state, data){
        console.log('data',data)
        state.myTeamData = data
    },
    setUpdatedTeamName(state, newTeamName){
        if(state.myTeamData){
            state.myTeamData = {
                ...state.myTeamData,
                team_name: newTeamName
            }
        }
    },
    // setUpdatedTeamName(state, data){
    //     state.updatedTeamName = data
    // },
    setContestType(state, data){
        state.contestType = data
    },
    setMyContest(state, data){
        state.myContest = data
    }
};
export default {
    state,
    getters,
    actions,
    mutations
}