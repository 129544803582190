<template>
    <div class="recent-bank-sec">
        <div class="recent-bank">
            <div class="bank-icon">
                <img loading="lazy" src="@/assets/images/right-icon.webp" alt="right-icon">
            </div>
            <div class="bank-con">
                <!-- <h6>Success!</h6> -->
                <span>{{ message }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModal",
    props: ['message'],
}
</script>