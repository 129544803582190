<template>
    <div class="recent-bank-sec bg-wrong">
        <div class="recent-bank">
            <div class="bank-icon">
                <img loading="lazy" src="@/assets/images/wrong-icon.webp" alt="wrong-icon">
            </div>
            <div class="bank-con">
                <!-- <h6>Wrong!</h6> -->
                <span>{{ message }}</span>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: "ErrorModal",
    props: ['message'],
}
</script>