const CricketFightModules = () => import(/* webpackChunkName : "cricket-fight-module"*/ './views/Module.vue')
const CricketFight = () => import(/* webpackChunkName :"cricket-fight" */ './views/CricketFight.vue')
const MyContests = () => import(/* webpackChunkName :"cricket-fight" */ './views/MyContests.vue')
const CricketBets = () => import(/* webpackChunkName :"cricket-fight" */ './views/CricketBets.vue')
const CricketFightMatchDetails = () => import(/* webpackChunkName :"cricket-fight" */ './views/CricketFightMatchDetails.vue')
const BetTeam = () => import(/* webpackChunkName :"cricket-fight" */ './views/BetTeam.vue')

const CricketFightRoutes ={
    path:'/',
    component:CricketFightModules,
    children : [
        {
            path:'cricket-fight',
            name:'CricketFight',
            component:CricketFight
        },
        {
            path:'my-constests',
            name:'MyContests',
            component:MyContests
        },
        {
            path:'crickets-bets/:id',
            name:'CricketBets',
            component:CricketBets
        },
        {
            path:'cricket-fight-match-details/:id',
            name:'CricketFightMatchDetails',
            component:CricketFightMatchDetails
        },
        {
            path:'bet-team',
            name:'BetTeam',
            component:BetTeam
        },
    ]
}

export default CricketFightRoutes;