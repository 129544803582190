import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

const dateTime = {
    sportItemDate: (date, format = 'D MMM, h:mm A') => {
        return dayjs(date).format(format);
    },
    convertUnixDate: (date, format = 'DD MMM YYYY HH:mm:ss') => {
        return dayjs.unix(date).format(format)
    },
    currentTimestamp: () => {
        return dayjs().unix();
    },
    getcurrentDate: () => {
        return dayjs(new Date()).format('YYYY-MM-DD');
    },
    formatDate: (date, format = 'DD MMM YYYY HH:mm:ss') => {
        return dayjs(date).format(format);
    },
    getYesterDayDate: (format = 'YYYY-MM-DD') => {
        return dayjs().subtract(1, 'day').format(format);
    },
    getDateSaperatedByDays: (days, date = new Date(), format = 'YYYY-MM-DD' ) => {
        return dayjs(date).subtract(days, 'day').format(format);
    },
    convertOnlyTime: (time) =>{
        return dayjs(time, "HH:mm").format("hh:mm A");
    },
    getUTCTime: (format = 'HH:mm:ss') =>{
        return dayjs.utc().format(format)
    },
    convertUtcDate: (date) => {
        return dayjs.utc(date);
    },
    formatEventDateToUTC(dateInput, format = 'DD/MM/YYYY HH:mm:ss (UTC Z)') {
        const timeZone = 'Asia/Kolkata';
        if (!dateInput) {
            console.error('Invalid date input:', dateInput);
            return 'Invalid date';
        }
        let date;
        try {
            if (typeof dateInput === 'number') {
                date = dayjs.unix(dateInput);
            } else if (typeof dateInput === 'string') {
                if (dateInput.includes('T') && dateInput.endsWith('Z')) {
                    date = dayjs(dateInput).utc();
                } else {
                    date = dayjs(dateInput);
                }
            } else {
                throw new Error('Invalid input type for date');
            }
            if (!date.isValid()) {
                throw new Error('Parsed date is invalid');
            }
            return date.tz(timeZone).format(format);
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    },
    checkIsForFutureDate:(date) => {
        const now = dayjs();
        return dayjs(date).isAfter(now)
    },
    getLocalDate:(date, format = 'DD MMM hh:mm A') => {
        const localDate = dayjs.utc(date).local().format(format);
        return localDate;
    },
    getDateForMatka(date, format="DD/MM/YYYY hh:mm A") {
        return dayjs.utc(date).add(5, 'hours').add(30, 'minutes').format(format);
    },
    getPreviousDate: (day = 1, format = 'YYYY-MM-DD') => {
        return dayjs().subtract(day, 'day').format(format);
    },

} 

export default dateTime