export const headers =  {
    'Content-Type': 'application/json',
    'Authorization': window.authorization_token
}
export const walletHeaders = {
    'Content-Type': 'application/json',
    'Authorization': window.wallet_token
}

export function setHeaders() {
  headers.Authorization =window.authorization_token
}
export function setWalletHeaders() {
  walletHeaders.Authorization =window.wallet_token
}