<template>
    <section class="not-found-wrapper">
        <div class="not-found-sec">
          <div class="found-img"><img loading="lazy" src="@/assets/images/not-found.png" alt="error"></div>
          <div class="found-heading"><h1>404</h1><p>Not Found</p>
          <router-link to="/" class="btn_submit_cl">Go To Homepage</router-link>
          </div>
        </div>
      </section>
  </template>
  
  <script>
  export default {
    name: "NotFound",
  };
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  
  .found-img img {
    width:160px;
    margin-bottom: 20px;
  }
  
  .found-img{
  text-align:center;
  }
  
  .found-heading {
    text-align: center;
  }
  .not-found-wrapper{
    background-color:var(--bg-body);
    height:calc(100dvh - 58px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .found-heading h1 {
    color: var(--text-highlight);
    font-size: 80px;
    font-weight: 800;
    line-height:60px;
    margin-bottom: .5rem;
  }
  
  .found-heading p {
    color: #707070;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.6
  }
  
  .found-heading .btn_submit_cl {
    margin-top: 10px;
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
    background: var(--btn-primary);
    color: var(--text-btn-primary);
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .align-items-center {
    align-items: center!important;
    flex-direction: column;
  }
  </style>
  