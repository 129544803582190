const StatmentModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const AccountStatment = () => import(/* webpackChunkName: "account-statment" */ './views/AccountStatment.vue');
const SportsBookResult = () => import(/* webpackChunkName: "sportsbook-result" */ './views/SportsBookResult.vue');
const LiveCasinoResult = () => import(/* webpackChunkName: "live-casino-result" */ './views/LiveCasinoResult.vue');
const ActivityLog = () => import(/* webpackChunkName: "activity-log" */ './views/ActivityLog.vue');
const Bets = () => import(/* webpackChunkName: "Bets" */ './views/Bets.vue');
const ProfitLoss = () => import (/*webpackChunkName: 'profit-loss" */ './views/ProfitLoss.vue');
const ProfitLossReportDetail = () => import(/* webpackChunkName: "profit-loss-report" */ './components/ProfitLossReportDetail.vue');
const CasinoProfitLossReportDetails = () => import(/* webpackChunkName: "profit-loss-report" */ './components/CasinoProfitLossReportDetail.vue')

const StatmentRoutes = {
  path: '/',
  component: StatmentModule,
  children: [
    {
      path: 'account-statment',
      name: 'account-statment',
      component: AccountStatment,
    },
    {
      path: 'sportsbook-result',
      name: 'sportsbook-result',
      component: SportsBookResult,
    },
    {
      path: 'live-casino-result',
      name: 'live-casino-result',
      component: LiveCasinoResult,
    },
    {
      path: 'activity-log',
      name: 'activity-log',
      component: ActivityLog,
    },
    {
      path: 'bets/:type',
      name: 'bets',
      component: Bets
    },
    {
      path: 'profit-loss',
      name: 'profit-loss',
      component: ProfitLoss
    },
    {
        path: 'profit-loss-report-detail',
        name: 'profit-loss-report-detail',
        component: ProfitLossReportDetail
    },
    {
        path: 'casino-profit-loss-report-details',
        name: 'casino-profit-loss-report-details',
        component: CasinoProfitLossReportDetails,
    }
  ],
}

export default StatmentRoutes;