import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from '@/modules/authorization/store';
import commonStore from '@/shared/store';
import statementStore from '@/modules/statment/store'
import fantasyCricketStore from '@/modules/cricket-fight/store';
import accountStore from '@/modules/account/store';

const mutations = {
    resetState(state) {
        state.auth.user = null;
        state.auth.token = null;
        state.auth = {
            user: null,
            chips: null,
            token: null,
            eventManage: null,
            eventDetailHideSections: [],
        };
        state.commonStore.siteVersion = null;
    }
}
const actions = {
    resetAllState({ commit }) {
        commit('resetState')
    }
}
const modules = {
  auth,
  commonStore,
  statementStore,
  fantasyCricketStore,
  accountStore
}

export default createStore({
  state: {},
  mutations,
  actions,
  modules: modules,
  plugins: [createPersistedState({
    key: 'sd:site'
})]
});