const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');
const Wallet = () => import(/* webpackChunkName: "wallet" */ './views/Wallet.vue');
const WalletHistory = () => import(/* webpackChunkName: "wallet-history" */ './views/WalletHistory.vue');
const WalletHistoryDetail = () => import(/* webpackChunkName: "wallet-history-detail" */ './views/WalletHistoryDetail.vue');
const WalletWithdraw = () => import(/* webpackChunkName: "wallet-withdraw" */ './views/WalletWithdraw.vue');
const Withdraw = () => import(/* webpackChunkName: "wallet-withdraw-details" */ './views/Withdraw.vue');
const AddWithdrawAccount= () => import(/* webpackChunkName: "add-withdraw-account" */ './views/AddWithdrawAccount.vue');
const WalletDeposit = () => import(/* webpackChunkName: "wallet-deposit" */ './views/WalletDeposit.vue');
const DepositPaymentMethods = () => import(/* webpackChunkName: "wallet-deposit" */ './views/DepositPaymentMethods.vue');

const WalletRoutes = {
  path: '/',
  component: WalletModule,
  children: [
    {
      path: 'wallet',
      name: 'wallet',
      component: Wallet,
      meta: {
        title: 'Wallet',
      },
    },
    {
      path: 'wallet-history',
      name: 'wallet-history',
      component: WalletHistory,
      meta: {
        title: 'Wallet History',
      },
    },
    {
      path: 'wallet-withdraw',
      name: 'wallet-withdraw',
      component: WalletWithdraw,
      meta: {
        title: 'Withdraw',
      },
    },
    {
      path: 'withdraw',
      name: 'withdraw',
      component: Withdraw,
      meta: {
        title: 'Withdraw',
      },
    },
    {
      path: 'add-withdraw-account/:type',
      name: 'add-withdraw-account',
      component: AddWithdrawAccount,
      meta: {
        title: 'Account Details',
      },
    },
    {
      path: 'wallet-deposit',
      name: 'wallet-deposit',
      component: WalletDeposit,
      meta: {
        title: 'Deposit',
      },
    },
    {
      path: 'deposit-payment-method',
      name: 'deposit-payment-method',
      component: DepositPaymentMethods,
      meta: {
        title: 'Deposit',
      },
    },
    {
        path: 'wallet-history-detail/:type',
        name: 'wallet-history-detail',
        component: WalletHistoryDetail,
    },
  ],
}

export default WalletRoutes;