
import api from './api';
import { routes } from './apiRoutes';
import { headers, walletHeaders } from '@/shared/services/headers';
import { errorToast } from './helper';

/**
 * Get the API route based on the route name and type.
 * @param {string} routeName - The name of the route.
 * @param {string} apiType - The type of the API ('user', 'sports', 'fantasy').
 * @returns {string} - The full API route.
 */
const getApiRoute = (routeName, apiType) => {
    const route = routes[apiType] && routes[apiType][routeName];

    if (!route) {
        throw new Error(`Route not found for type: ${apiType} and name: ${routeName}`);
    }
    return route;
};

/**
 * @param {string} [method='get'] - The HTTP method for the request (e.g., 'get', 'post', 'put', 'delete').
 * @param {string} routeName - The name of the route to be accessed. This is required.
 * @param {object} [data=null] - The payload to send with the request. Used for methods like POST or PUT.
 * @param {string} [apiType='user'] - The API type, which determines the API instance to use.
 *  - Possible values:
 *    - 'user': Default API type for user-related requests.
 *    - 'sports': API type for sports-related requests.
 *    - 'fantasy': API type for fantasy-related requests.
 *    - 'wallet': API type for wallet-related requests.
 * @param {object} [params=null] - URL parameters to include in the request. If a parameter matches
 * a placeholder in the route (e.g., `:id`), it will be replaced in the URL.
 * @returns {Promise} - A Promise resolved with the API response or rejected with an error.
 *
 * ### Behavior:
  1. *Route Name and API Type**:
 *    - Constructs the URL by calling `getApiRoute(routeName, apiType)`.
  2. *Fantasy API-Specific Logic**:
 *    - Prepends `fantasycricket` or `fantasy` to the URL based on the HTTP method when `apiType` is `'fantasy'`.
  3. *URL Parameter Replacement**:
 *    - Dynamically replaces placeholders (e.g., `:id`) in the route with corresponding values from `params`.
 *    - Removes the replaced keys from the `params` object.
  4. *Headers**:
 *    - Selects headers based on `apiType`. Uses:
 *      - `walletHeaders` for `'wallet'` API.
 *      - `headers` for all other API types.
  5. *API Request**:
 *    - Uses the appropriate Axios instance (`api[apiType]`) to make the API call.
 * 
 * #### Example: Route with URL Parameters
 * javascript
 * apiCall('get', 'getUserById', null, 'user', { id: 123 });
 * 
 * - Route: `/user/:id`
 * - Resulting URL: `/user/123`
 *
 * @see getApiRoute
 * @see walletHeaders
 * @see headers
 */
export const apiCall = (method = 'get', routeName, data = null, apiType = 'user', params = null, customContentType = null, customQueryString = null) => {
    if (!routeName) {
        throw new Error('routeName is required');
    }

    let url = getApiRoute(routeName, apiType);
    let apiHeaders = apiType == 'wallet' ?  walletHeaders : headers;
    if(data instanceof FormData){
        apiHeaders['Content-Type'] = 'multipart/form-data';
    }
    if(apiType == 'fantasy') {
        if(method == 'get') {
            url = 'fantasycricket' + url;
        } else if(method == 'post') {
            url = 'fantasy' + url;
        }
    }

    if(params) {
        Object.keys(params).forEach(key => {
            if(url.includes(`:${key}`)) {
                url = url.replace(`:${key}`, params[key]);
                delete params[key]
            }
        });
    }
    if (customQueryString) {
        url += customQueryString;
    }
    return api[apiType]({
        method,
        url,
        data,
        params,
        headers : apiHeaders
    })
    
};